import React from "react";
import QCR from "../images/sponsors/qcr.svg";
import Boeing from "../images/sponsors/boeing.svg";
import EER from "../images/sponsors/EER.png";


import read_more from '../db/QUTRC_2023-2024_Sponsorship_Package.pdf'

import '../css/sponsors.css'

const Sponsors = () => {
	return (
		<div className="Sponsors">
			<div className="sponsors-container">
				{/* <image src={SponsorsList} alt="Sponsors List" width="100%" height="100%" /> */}
				<div id="about-sponsors">
					<h1>About sponsors</h1>
					<p className="left">Established in 2011, the QUT Robotics Club (QUTRC) is a student-run organization
						providing students with opportunities to enhance their robotics skills and establish industry connections.</p>

					<p className="left">To achieve this goal, QUTRC stages educational and project-based workshops, hosts networking opportunities, 
          and organises industry presentations to encourage student participation and inclusivity. Our sponsors provide resources and support 
          in these various activities.</p>
				</div>

				<div id="why-sponsor-us">
					<h1>Why sponsor us?</h1>
					<p className="justify">Sponsoring QUTRC will significantly support our efforts to encourage and foster the emerging roboticists of the future over the course of 2023-2024. 
          Your company will have opportunities not only to help students expand their knowledge, but also to meet and handpick the engineers you want to work with in the future.
					</p>

					<div>
						<a href={read_more} target="blank__">
							<button className="styled-button" size="lg">QUTRC 2023-2024 <br></br>Sponsor Package</button>
						</a>
					</div>
				</div>
				
				<div id="club-sponsors">
					<h1>Club sponsors</h1>

					<div className="sponsor">
						<a href="https://www.qut.edu.au/about/faculty-of-engineering/school-of-electrical-engineering-and-robotics" target="_blank" rel="noreferrer">
							<img src={EER} className="img" alt="EER logo" height="100" />
						</a>
						<div className="sponsor-blurb">
							<p>The QUT Robotics Club is supported by the QUT School of Electrical Engineering and Robotics (EER). The School of EER aims to improve how 
								we understand and take care of our world through sustainable energy solutions and intelligent technology. They focus on high-quality, 
								cross-disciplinary teaching and research in robotic vision, machnie learning, video analytics, wireless power transfer, microgrids, 
								renewable energy integration and superconductivity. The School of EER provides financial support for the aim of running our projects, 
								especially our Robots101 program, and our Australian Rover Challenge entries. They also provide us with workshop space to complete our projects.
							</p>
						</div>
					</div>
					
					<div className="sponsor">
						<a href="https://research.qut.edu.au/qcr/" target="_blank" rel="noreferrer">
							<img src={QCR} className="img" alt="QCR logo" height="100" />
						</a>
						<div className="sponsor-blurb">
							<p>The Queensland Centre for Robotics is one of the world’s leading robotic research facilities, 
								focussing on innovation in intelligent robotics, translating research into commercial and societal outcomes, 
								and leading the education, training and development of talent to meet the growing demands for robotics. 
								The QCR has provided a substantial amount of assistance and support for the Droid Racing Challenge and 
								Artemis our entry into the 2023 Australian Rover Challenge. They are our close collaborators, as well as a valued sponsor and supporter, 
								as many of our students and executives have gone on to research or work there.
							</p>
						</div>
					</div>


				</div>

			</div>

		</div>
	);
}

export default Sponsors;